import {Component, Input} from '@angular/core';
import {CmsItem} from '../../../model/cms-item.model';

@Component(
    {
        selector: 'lib-core-cms-item',
        templateUrl: `./cms-item.component.html`,
    }
)
export class CmsItemComponent {
    @Input() public cmsItem: CmsItem;
}
